<template>
	<div class="container">
		<back />
		<div class="content">
			<div class="box-right">
				<div v-for="(projectTask,index) in projectTaskList" :key="index">
					<div v-if="projectTask.list&&projectTask.list.length>0" style="margin-top: 30px;font-size: 30px;">
						{{projectTask.user.dept.deptName}}
					</div>
					<el-table v-if="projectTask.list&&projectTask.list.length>0" stripe :data="projectTask.list" border
						style="width: 100%;min-height: 600px;"
						:header-cell-style="{ background: getCorlorByDept(projectTask.user.dept), color: '#fff' }"
						max-height="600">
						<el-table-column label="工作任务" prop="taskContent">
						</el-table-column>
						<el-table-column label="备注" prop="remark">
						</el-table-column>
						<el-table-column width="100" label="完成情况">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.completion==0">进行中</el-tag>
								<el-tag v-if="scope.row.completion==1" type="success">已完成</el-tag>
								<el-tag v-if="scope.row.completion==2" type="info">未开始</el-tag>
							</template>
						</el-table-column>
						<el-table-column width="180" label="完成时间" prop="completionTime">
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<el-dialog :close-on-click-modal="false" :destroy-on-close="true" :title="title" :visible.sync="dialogVisible"
			width="40%" @colse="haha">
			<el-form :model="ruleForm" label-position="left" :rules="rules" ref="ruleForm" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="项目名称" prop="projectName">
					<el-input v-model="ruleForm.projectName" placeholder="请输入项目名"></el-input>
				</el-form-item>
				<el-form-item label="项目类型" prop="type">
					<el-select v-model="ruleForm.type" placeholder="请选择项目状态" :disabled="!ruleForm.projectId">
						<el-option label="重要且紧急" :value="1"></el-option>
						<el-option label="重要不紧急" :value="2"></el-option>
						<el-option label="紧急不重要" :value="3"></el-option>
						<el-option label="不重要不紧急" :value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="项目负责人">
					<el-checkbox-group v-model="ruleForm.head">
						<el-checkbox v-for="item in checkList" :label="item" :key="item"></el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="客户单位" prop="customer">
					<el-input v-model="ruleForm.customer" placeholder="请输入客户单位"></el-input>
				</el-form-item>
				<el-form-item label="项目地点" prop="address">
					<el-input v-model="ruleForm.address" placeholder="请输入项目地点"></el-input>
				</el-form-item>
				<el-form-item label="项目金额" prop="estimatedAmout">
					<el-input :max="8" type="number" v-model="ruleForm.estimatedAmout" placeholder="请输入项目金额"></el-input>
				</el-form-item>
				<!-- <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item> -->
			</el-form>
			<span slot="footer">
				<!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
				<!-- <el-button @click="dialogVisible = false">取消</el-button> -->
				<el-button type="primary" @click="submitForm('ruleForm')">{{ruleForm.projectId?'立即修改':'立即创建'}}
				</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import {
		getProject,
		getAllName,
		updatePlan,
		projectTask
	} from "@/api";
	import back from "@/components/back";
	export default {
		name: "sbu",
		components: {
			back,
		},
		data() {
			return {
				checkList: [],
				ruleForm: {},
				title: '',
				dialogVisible: false,
				rules: {
					projectName: [{
							required: true,
							message: '请输入项目名称',
							trigger: 'blur'
						},
						{
							min: 3,
							message: '输入内容不得小于3个字符',
							trigger: 'blur'
						}
					],
					type: [{
						required: true,
						message: '请选择项目状态',
						trigger: 'change'
					}],
					headArr: [{
						type: 'array',
						required: true,
						message: '请至少选择一个负责人',
						trigger: 'blur'
					}],
					head: [{
						type: 'array',
						required: true,
						message: '请至少选择一个负责人',
						// trigger: 'blur'
					}],
					customer: [{
							required: true,
							message: '请输入客户单位',
							trigger: 'blur'
						},

					],
					address: [{
						required: true,
						message: '请输入项目地点',
						trigger: 'blur'
					}, ],

					estimatedAmout: [{
							required: true,
							message: '请填写项目金额',
							trigger: 'blur'
						},
						// {
						//     max: 10,
						//     message: '最多10位数,你以为你很牛b?',
						//     trigger: 'blur'
						// }
					]
				},
				panelList: [{
						text: "悦艺",
						color: "#C7B016",
						type: 1,
						workList: [],
					},
					{
						text: "悦读",
						color: "#699CCC",
						type: 2,
						workList: [],
					},
					{
						text: "悦动",
						color: "#40C291",
						type: 3,
						workList: [],
					},
					{
						text: "悦耀",
						color: "#D64544",
						type: 4,
						workList: [],
					}
				],
				projectTaskList: [],
			}
		},
		methods: {
			getCorlorByDept(dept) {
				let obj = this.panelList.find(item => item.text == dept.deptName)
				// console.log(obj);
				return obj.color
			},
			getProjectTask() {
				projectTask().then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.projectTaskList = res.data
					}
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ruleForm.head = this.ruleForm.head.toString()
						// console.log(this.ruleForm.head);
						updatePlan(this.ruleForm).then((res) => {
							if (res.code == 200) {
								this.$message({
									message: (this.ruleForm.projectId ? '修改' : '添加') + '成功!',
									type: 'success'
								})
								this.dialogVisible = false
								this.getProjectData()
							} else {
								this.$message.error(ers.msg)
							}
						}).catch(err => {
							this.ruleForm.head = this.ruleForm.head.split(',')
						})
					} else {
						// console.log('error submit!!');
						return false;
					}
					// if (!valid) {
					// 	return false;
					// }
				});

			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			reset() {
				this.ruleForm = {
					projectName: null,
					type: null,
					head: [],
					estimatedAmout: null
				};
			},
			openDialog(item) {
				if (item.projectId == undefined || item.projectId == null) { //新增
					this.title = "新建项目"
					this.reset()
					this.ruleForm.type = item.type //设置类型
				} else {
					this.title = "新建项目"
					this.ruleForm = JSON.parse(JSON.stringify(item))
				}
				this.dialogVisible = true
			},
			//获取所有名字
			getNameList() {
				getAllName().then(res => {
					if (res.code == 200) {
						this.checkList = res.data
					}
				})
			},
			haha() {
				// console.log(222);
				this.reset()
			},
			getProjectData() {
				getProject().then(res => {
					if (res.code === 200) {
						this.panelList.forEach(item => {
							let arr = res.data.filter(i => i.type == item.type)
							arr = arr.map(obj => {
								obj.head = obj.head.split(',')
								return obj
							})
							item.workList = arr
						})
					}
				})
			}
		},
		created() {
			this.getProjectData();
			this.getNameList()
			this.getProjectTask()
		},
	}
</script>

<style lang="scss" scoped>
	.container {
		width: 100vw;
		height: 100vh;
		padding: 30px 50px;
		background-image: linear-gradient(#FFFDFB, #ffe5d1);
		overflow: auto;

		.box-right {
			margin-top: 100px;
			padding: 0 80px;

			.panel-box {
				border-radius: 5px;
				box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
				// border: 1px solid #000;
				width: 49%;
				height: 450px;
				margin-bottom: 20px;
				overflow: auto;

				.panel-header {
					padding: 0 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: sticky;
					top: 0;
					font-size: 22px;
					font-weight: bold;
					// background-color: #C83434;
					color: #fff;
					line-height: 50px;
					text-align: center;
					z-index: 999;

					span {
						display: flex;
						justify-content: center;
						align-items: center;

						i {
							font-size: 27px;
							color: rgba(0, 0, 0, .5);
						}
					}
				}

				.panel-container {
					height: calc(100% - 50px);
					overflow: hidden;
					background-color: #f5f6f7;

					.card {
						padding: 20px;
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: space-between;
						overflow: auto;
						flex-wrap: wrap;

						.card-box {
							position: relative;
							padding: 20px;
							border-radius: 5px;
							background-color: #fff;
							margin-bottom: 20px;
							width: 49%;
							height: min-content;
							// height: 250px;
							box-shadow: 0 0 10px rgba($color: #000000, $alpha:.1);

							// border: 1px solid #ccc;
							.img {
								position: absolute;
								bottom: 0;
								left: 0;
								height: 100px;
								width: 100px;
							}

							.card-head {
								display: flex;
								justify-content: space-between;
								align-items: center;

								h2 {
									line-height: 40px;

									height: auto;
									word-wrap: break-word;
									/*强制换行*/
									overflow: hidden;
									/*超出隐藏*/
									text-overflow: ellipsis;
									/*隐藏后添加省略号*/
									white-space: nowrap;
									/*强制不换行*/

								}
							}

							.card-plan {
								display: flex;
								justify-content: space-between;
								align-items: center;

								.plan-info {
									// text-indent: 1.5em;
									// padding-left: 20px;
									width: 55%;
									text-align: left;
									line-height: 30px;
									color: #999;

									span {
										color: #000000;

									}

									// .tag {
									//     padding: 10px;
									// }
								}

								.progress {
									width: 40%;
									height: 150px;
									position: relative;
									border-right: 1px solid #ccc;

									.circle {
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
									}

									.txt {
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
										display: flex;
										flex-direction: column;
										text-align: center;

										.num {
											font-size: 18px;
											font-weight: bold;

											.current {
												font-size: 30px;
												color: #409EFF;
											}
										}

										.total {
											width: 100px;

										}
									}
								}

							}

						}
					}


					// .panel-content {
					// 	background-color: rgb(227, 227, 227, .36);
					// 	border-bottom: 1px solid #ccc;
					// 	padding: 10px 20px;

					// 	font-size: 20px;
					// 	display: flex;
					// 	justify-content: space-between;
					// 	align-items: center;

					// 	.title {
					// 		// max-width: 350px;
					// 		margin-right: auto;
					// 		margin-left: 20px;
					// 		cursor: pointer;
					// 		// word-break: break-all;
					// 		// text-overflow: ellipsis;
					// 		// display: -webkit-box;
					// 		// -webkit-box-orient: vertical;
					// 		// -webkit-line-clamp: 2;
					// 		// /* 这里是超出几行省略 */
					// 		// overflow: hidden;
					// 	}

					// 	.active {
					// 		text-decoration: line-through;
					// 		color: #ccc;
					// 	}
					// }
				}

				.bg {
					background-color: aliceblue;
				}
			}
		}
	}
</style>
